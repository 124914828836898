import React, { useEffect, useState } from "react";
import { Space, Input, Select, Tooltip } from "antd"

import SvgIcon from "src/components/SvgIcon";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";
import TableCustom from "src/components/Table/CustomTable";
import ToolBarCustomLayer from "src/components/ToolBarCustom/ToolBarCustomLayer";
import { InputSearchStyle } from "src/pages/ADMIN/Accounting/Setting/common/styled";
import IconBase from "src/components/IconBase"
import PopupAction from "src/components/PopupAction"

const columns = [
  {
    title: "TT",
    dataIndex: "TT",
    render: (val, record, idx) => <div className="text-center">{idx + 1}</div>,
    align: "center",
  },
  {
    title: "Mã TD",
    dataIndex: "code",
    align: "center",
  },
  {
    title: "Tiêu đề",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "Phòng ban",
    dataIndex: "department",
    align: "center",
  },
  {
    title: "Vị trí công việc",
    dataIndex: "position",
    align: "center",
  },
  {
    title: "Nơi làm việc",
    dataIndex: "location",
    align: "center",
  },
  {
    title: "Số lượng tuyển",
    dataIndex: "numOfCandidates",
    align: "center",
  },
  {
    title: "Hạn nộp hồ sơ",
    dataIndex: "applicationDeadline",
    align: "center",
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Người tạo",
    dataIndex: "creator",
    align: "center",
  },
  {
    title: <Tooltip title={'Hành động'}>_</Tooltip>,
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      return (
        <PopupAction>
          <div onClick={() => {}}>
            <IconBase name="edit" />
            <span>Sửa</span>
          </div>
          <div onClick={() =>
            CB1({
              title: `Bạn có chắc chắn muốn xoá bản ghi này không?`,
              icon: "trashRed",
              okText: "Đồng ý",
              onOk: async close => {
                close()
              },
            })
          }>
            <IconBase name="delete" />
            <span>Xóa</span>
          </div>
        </PopupAction>
      );
    },
  },
];

const _dataSource = [
  {
    key: 1,
    code: "TD-00004",
    title: "Tuyển nhân viên kinh doanh",
    department: "Phòng Kinh Doanh",
    position: "Nhân viên",
    location: "Hà Nội",
    numOfCandidates: 10,
    applicationDeadline: "31/01/2020",
    status: "Đang chờ",
    creator: "Phạm Văn Trọng",
  },
  {
    key: 2,
    code: "TD-00003",
    title: "Tuyển Fresher",
    department: "Phòng Kinh Doanh",
    position: "Thực tập",
    location: "Hà Nội",
    numOfCandidates: 5,
    applicationDeadline: "31/01/2019",
    status: "Đã duyệt",
    creator: "Phạm Văn Trọng",
  },
];

function CandidateProfileComponent(props) {
  const [dataSource, setDataSource] = useState(_dataSource);
  const [dataSelected, setDataSelected] = useState([]);

  useEffect(() => {}, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelected(selectedRows);
    },
  };

  const itemsLeft = [
    {
      key: "delete",
      title: "Xóa hàng đã chọn",
      disable: !dataSelected.length,
      onClick: () => {
        CB1({
          title: `Bạn có chắc chắn muốn xoá <strong> ${dataSelected.length}</strong> bản ghi không?`,
          icon: "trashRed",
          okText: "Đồng ý",
          onOk: async (close) => {
            close();
          },
        });
      },
    },
    // {
    //   key: "drop_down",
    //   component: (
    //     <Select
    //       defaultValue="all"
    //       style={{ width: 120 }}
    //       onChange={() => {}}
    //       options={[
    //         {
    //           value: "all",
    //           label: "Tất cả",
    //         },
    //         {
    //           value: "expired",
    //           label: "Hết hạn",
    //         },
    //         {
    //           value: "pending",
    //           label: "Chưa duyệt",
    //         },
    //       ]}
    //     />
    //   ),
    // },
  ];

  const itemsRight = [
    {
      key: "input_search",
      component: (
        <InputSearchStyle>
          <Input
            className="custom-input"
            maxLength={50}
            placeholder="Mã TD, tiêu đề ..."
            onChange={(e) => {}}
            onPressEnter={(e) => {}}
          />
          <div className="custom-icon-search">
            <SvgIcon name="searchStatistic" onClick={() => {}} />
          </div>
        </InputSearchStyle>
      ),
    },
    {
      key: "setting",
      title: "Cài đặt",
      onClick: () => {},
    },
  ];

  return (
    <div>
      <ToolBarCustomLayer itemsLeft={itemsLeft} itemsRight={itemsRight} />
      <TableCustom
        isPrimary
        columns={columns}
        dataSource={dataSource}
        showPagination
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        footerLeft={<div className="d-flex mt-20" />}
        widthScroll={1200}
        textEmpty="Không có dữ liệu!"
      />
    </div>
  );
}

export default CandidateProfileComponent;
