import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Loại phiếu chi
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Số phiếu báo nợ",
        dataIndex: "debitNoteNumber",
        key: "debitNoteNumber",
    },
    {
        title: "Nhà cung cấp",
        dataIndex: "supplier",
        key: "supplier",
    },
    {
        title: "Tổng tiền",
        dataIndex: "totalAmount",
        key: "totalAmount",
    },
    {
        title: "Ngày hạch toán",
        dataIndex: "accountingDate",
        key: "accountingDate",
    },
    {
        title: "Ngày chứng từ",
        dataIndex: "documentDate",
        key: "documentDate",
    },
    {
        title: "Hạch toán",
        dataIndex: "accounting",
        key: "accounting",
    },
];

const _dataSource = [
    {
        key: 1,
        debitNoteNumber: "DN001",
        supplier: "Supplier A",
        totalAmount: 1000,
        accountingDate: "2024-01-01",
        documentDate: "2024-01-01",
        accounting: "Done",
    },
    {
        key: 2,
        debitNoteNumber: "DN002",
        supplier: "Supplier B",
        totalAmount: 2000,
        accountingDate: "2024-01-02",
        documentDate: "2024-01-02",
        accounting: "Pending",
    },
    {
        key: 3,
        debitNoteNumber: "DN003",
        supplier: "Supplier C",
        totalAmount: 3000,
        accountingDate: "2024-01-03",
        documentDate: "2024-01-03",
        accounting: "Done",
    }
];

function GoodsReturned(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default GoodsReturned;
