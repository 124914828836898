import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SvgIcon from "src/components/SvgIcon"
import ButtonCircle from "src/components/MyButton/ButtonCircle"

// Styled
const ToolBarStyle = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0;
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
`

const ToolBarLeft = styled.div`
  display: flex;
  align-items: center;
`

const ToolBarRight = styled.div`
  display: flex;
  align-items: center;
`

const EleStyle = styled.div`
  margin: 0 5px;
`

export const ICON = {
  delete: "delete-outline",
  send_email: "sms-message",
  export_excel: "import",
  import_excel: "download",
  create_event: "add-box",
  create_person_charge: "register",
  setting: "config",
  printer: "printer",
  reload: "backup",
  check: "check-blu",
  document: "sign",
  chat: "mode-comment",
  search_detail: "filter-table",
}

export const ITEMS_LEFT = {
  delete: "delete",
  send_email: "send_email",
  export_excel: "export_excel",
  import_excel: "import_excel",
  create_event: "create_event",
  create_person_charge: "create_person_charge",
  drop_down: "drop_down",
  printer: "printer",
  chat: "chat",
  date_picker: "date_picker",
  search_detail: "search_detail",
  reload: "reload",
  text: "text"
}

export const ITEMS_RIGHT = {
  input_search: "input_search",
  send_email: "send_email",
  export_excel: "export_excel",
  import_excel: "import_excel",
  setting: "setting",
  reload: "reload",
  check: "check",
  document: "document",
  chat: "chat",
  date_picker: "date_picker",
  search_detail: "search_detail",
  approve_work:"approve_work,"
}

function Index(props) {
  const { itemsLeft, itemsRight, activeToolBar, ...otherProps } = props

  const renderContent = (originData, data) => {
    return (
      <>
        {data
          .filter(item => Object.keys(originData).includes(item.key))
          .map(item => {
            const {
              key,
              component,
              icon,
              onClick,
              disable,
              isActive,
              title,
              color,
              ...restProps
            } = item
            if (React.isValidElement(component)) {
              if (disable) return null
              return (
                <EleStyle key={item.key}>
                  {React.cloneElement(component, { ...restProps })}
                </EleStyle>
              )
            }
            return (
              <>
                {disable ? null : (
                  <EleStyle key={item.key}>
                    {React.isValidElement(icon) ? (
                      icon
                    ) : (
                      <ButtonCircle
                        enable={isActive}
                        title={title}
                        onClick={onClick}
                        fill={color || "#01638D"}
                        iconName={ICON[item.key]}
                      />
                    )}
                  </EleStyle>
                )}
              </>
            )
          })}
      </>
    )
  }

  return (
    <>
      {activeToolBar && (
        <ToolBarStyle {...otherProps}>
          <ToolBarLeft>
            {itemsLeft?.length > 0 && renderContent(ITEMS_LEFT, itemsLeft)}
          </ToolBarLeft>
          <ToolBarRight>
            {itemsRight?.length > 0 && renderContent(ITEMS_RIGHT, itemsRight)}
          </ToolBarRight>
        </ToolBarStyle>
      )}
    </>
  )
}

Index.propTypes = {
  itemsLeft: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf(Object.values(ITEMS_LEFT)).isRequired,
      component: PropTypes.element.isRequired,
    }),
  ),
  itemsRight: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf(Object.values(ITEMS_LEFT)).isRequired,
      component: PropTypes.element.isRequired,
    }),
  ),
  activeToolBar: PropTypes.bool,
  style: PropTypes.object,
}

Index.defaultProps = {
  itemsLeft: [],
  itemsRight: [],
  activeToolBar: true,
}

export default React.memo(Index)

