export const apiPersonnelDetail = id => `/1.0/personnel/${id}/detail`
export const apiDeletePersonal = `/1.0/personnel/delete`
export const apiGetListBonus = `/1.0/personnel/bonus/list`
export const apiWorkHistoryList = `/1.0/personnel/work-history/list`
export const apiWorkHistoryCreate = id => `/1.0/personnel/work-history/create`
export const apiPersonnelUpdate = id => `/1.0/personnel/${id}/update`
export const apiPersonnelCreate = `/1.0/personnel/create`
export const apiProvince = `/1.0/province`
export const apiCountry = `/1.0/country`
export const apiWorkingProcessList = `/1.0/personnel/working-processes/list`
export const apiWorkingProcessCreate = `/1.0/personnel/working-processes/create`
export const apiWorkingProcessDelete = `/1.0/personnel/working-processes/delete`
export const apiWorkingProcessUpdate = id =>
  `/1.0/personnel/working-processes/${id}/update`
export const apiListSalaryHistory = `/1.0/personnel/salary-history/list`
export const apiSalaryHistoryCreate = `/1.0/personnel/salary-history/create`
export const apiSalaryHistoryUpdate = id =>
  `/1.0/personnel/salary-history/${id}/update`
export const apiSalaryHistoryDelete = `/1.0/personnel/salary-history/delete`
export const apiAllowanceHistory = `/1.0/personnel/allowance-history/list`
export const apiGetListContract = `/1.0/personnel/contract/list`
export const apiContractCreate = `/1.0/personnel/contract/create`
export const apiContractUpdate = id => `/1.0/personnel/contract/${id}/update`
export const apiListInsurance = "/1.0/personnel/insurance/list"
export const apiInsuranceDetail = id => `/1.0/personnel/insurance/${id}/detail`
export const apiInsuranceCreate = `/1.0/personnel/insurance/create`
export const apiInsurancePayment = id =>
  `/1.0/common/contract-data-by-personnel-id?id=${id}`
export const apiTimekeepingList = "/1.0/attendance/list"
export const apilistWorkDay = "/1.0/attendance/listWorkDay"
export const apiApprove = "/1.0/attendance/approve"
export const apiTimekeepingImportFile = "/1.0/attendance/import-file"
export const apiGetDepartmentList = "/1.0/common/department-list"
export const apiGetPositionList = "/1.0/position-title"
export const apiPersonnelListByDeptId = "/1.0/common/personnel-list"
export const apiGetContractTypeList = "/1.0/common/contract-type-list"
export const apiGetSignatoryList = "/1.0/common/representative-list"
export const apiGetContractDetail = id => `/1.0/personnel/contract/${id}/detail`
export const apiListContact = "/1.0/crm/contact/list"
export const apiGetCustomerListAll = "/1.0/crm/customer/listAll"
export const apiGetCustomerDetail = id => `/1.0/crm/customer/detail/${id}`
export const apiUpdateCustomer = id => `/1.0/crm/customer/update/${id}`
export const apiGetCustomerList = '/1.0/crm/customer/list'
export const apiListOrder = "/1.0/crm/order/list"
export const apiGetContactDetail = id => `/1.0/crm/contact/${id}/detail`
export const apiUpdateContactStatus = id => `/1.0/crm/contact/${id}/updateStatus`
export const apiOrderCreate = "/1.0/crm/order/create"
export const apiGetOrderDetail = id => `/1.0/crm/order/${id}/detail`
export const apiOrderUpdate = id => `/1.0/crm/order/${id}/update`
export const apiImport = "/1.0/crm/contact/import"
export const apiCustomerImport = "/1.0/crm/customer/import"
export const apiContactCallToken = '/1.0/crm/contact/callToken'
export const apiGetAgentListAll = '/1.0/crm/agent/listAll'
export const apiAddAgentPersonnel = '/1.0/crm/agent/addPersonnel'
export const apiRemoveAgentPersonnel = '/1.0/crm/agent/leavePersonnel'
export const apiUnlockUser = id => `/1.0/personnel/${id}/unlockUser`
export const apiLockUser = id => `/1.0/personnel/${id}/lockUser`

