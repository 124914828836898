import { useState } from "react";
import { useParams } from "react-router-dom";
import TabsCustom from "src/components/TabsCustom";
import TableCustom from "src/components/Table/CustomTable";
import LayoutContenAdmin from "src/components/Layouts/LayoutContenAdmin";
import ToolBarCustom from "src/components/ToolBarCustom";
import { WrapperStyle } from "src/pages/ADMIN/HumanResource/styled";
import { ToolBarStyle } from "../../Setting/common/styled";
import Button from "src/components/MyButton/Button";
import { StyleTabsCustom } from "src/components/TabsCustom/styled";
import OtherExpensesCredit from "./OtherExpensesCredit";
import GoodsReturnedCredit from "./GoodsReturnedCredit";
import SupplierFeeCredit from "./SupplierFeeCredit";
import { InputSearchStyle } from "../../../HumanResource/Work/Task/styled";
import { Input } from "antd";
import SvgIcon from "src/components/SvgIcon";

const Credit = (props) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [tab, setTab] = useState('1')

  const LABEL = {
    1: "CHI NHÀ CUNG CẤP",
    2: "CHI KHÁC",
    3: "HÀNG TRẢ LẠI",
 
  }


  const items = props => [
    {
        key: "1",
        label: <div className="text-tile-tabs">Chi nhà cung cấp</div>,
        children: <SupplierFeeCredit />,
        
    },
    {
        key: "2",
        label: <div className="text-tile-tabs">Chi khác</div>,
        children: <OtherExpensesCredit />,

    },
    {
        key: "3",
        label: <div className="text-tile-tabs">Hàng trả lại</div>,
        children: <GoodsReturnedCredit/>,

        
    },
]

  const onChangeTabs = (nameTab) => {
    setDisableBtn(nameTab === "book_setting")
    setTab(nameTab);
};

  return (
    <div>
      <WrapperStyle>
        <ToolBarStyle>
          <div className="left-side">{LABEL[tab]}</div>
          <div className="right-side">
            <Button btnType="primary1" onClick={() => { }} size="small" disabled={disableBtn}>
              Tạo mới
            </Button>
          </div>
        </ToolBarStyle>
        <div style={{display:"flex",justifyContent:"end"}}>
                <InputSearchStyle >
                    <Input
                        className="custom-input"
                        maxLength={50}
                        placeholder="Tìm kiếm"
                        onChange={(e) => { }}
                        onPressEnter={(e) => { }}
                    />
                    <div className="custom-icon-search">
                        <SvgIcon name="searchStatistic" onClick={() => { }} />
                    </div>
                </InputSearchStyle>
                </div>
        <StyleTabsCustom>
          <TabsCustom items={items(props)} defaultActiveKey="external" type="card" onchange={onChangeTabs} />
        </StyleTabsCustom>
      </WrapperStyle>
    </div>
  );
};

export default Credit;
