import { useState } from "react";
import TabsCustom from "src/components/TabsCustom";
// import TableCustom from "src/components/Table/CustomTable";
// import LayoutContenAdmin from "src/components/Layouts/LayoutContenAdmin";
// import ToolBarCustom from "src/components/ToolBarCustom";
import { WrapperStyle } from "src/pages/ADMIN/HumanResource/styled";
import Button from "src/components/MyButton/Button";
import { StyleTabsCustom } from "src/components/TabsCustom/styled";
import { ToolBarStyle } from "../../HumanResource/Work/Task/styled";
import InventoryAdjustment from "./compenent/InventoryAdjustment";

const InventoryAdjustmentSlip = (props) => {
    const [disableBtn, setDisableBtn] = useState(false);
    const [tab, setTab] = useState('1')
    const items = props => [
        {
            key: "1",
            label: <div className="text-tile-tabs">Phiếu điều chỉnh tồn kho</div>,
            children: < InventoryAdjustment/>,
            
        },
     
    ]
    const LABEL = {
        1: "PHIẾU ĐIỀU CHỈNH TỒN KHO",
       
      }

    const onChangeTabs = (nameTab) => {
        setDisableBtn(nameTab === "book_setting")
        setTab(nameTab);
    };


    return (
        <div>
            <WrapperStyle>
                <ToolBarStyle>
                    <div className="left-side">PHIẾU ĐIỀU CHỈNH TỒN KHO</div>
                    <div className="right-side">
                        <Button btnType="primary1" onClick={() => { }} size="small" disabled={disableBtn}>
                            Tạo mới 
                        </Button>
                    </div>
                </ToolBarStyle>
                <InventoryAdjustment/>
                {/* <StyleTabsCustom>
                    <TabsCustom items={items(props)} defaultActiveKey="external" type="card" onchange={onChangeTabs} />
                </StyleTabsCustom> */}
            </WrapperStyle>
        </div>
    );
};

export default InventoryAdjustmentSlip;
