import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Cột bảng danh sách kho
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Mã kho",
        dataIndex: "warehouseCode",
        key: "warehouseCode",
    },
    {
        title: "Tên kho",
        dataIndex: "warehouseName",
        key: "warehouseName",
    },
    {
        title: "Địa chỉ",
        dataIndex: "address",
        key: "address",
    },
    {
        title: "Ghi chú",
        dataIndex: "note",
        key: "note",
    },
    {
        title: "Ngày tạo kho",
        dataIndex: "creationDate",
        key: "creationDate",
    },
    {
        title: "Người tạo",
        dataIndex: "manager",
        key: "manager",
    },
];

const _dataSource = [
    {
        key: 1,
        warehouseCode: "W001",
        warehouseName: "Kho A",
        address: "Số 1, Đường ABC, Thành phố X",
        note: "Kho chính",
        creationDate: "2024-08-01",
        manager: "Nguyễn Văn A",
    },
    {
        key: 2,
        warehouseCode: "W002",
        warehouseName: "Kho B",
        address: "Số 2, Đường DEF, Thành phố Y",
        note: "Kho phụ",
        creationDate: "2024-08-05",
        manager: "Trần Thị B",
    },
    {
        key: 3,
        warehouseCode: "W003",
        warehouseName: "Kho C",
        address: "Số 3, Đường GHI, Thành phố Z",
        note: "Kho tạm",
        creationDate: "2024-08-10",
        manager: "Lê Văn C",
    },
];

function WarehouseListComponent(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default WarehouseListComponent;
