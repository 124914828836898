import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Cột bảng dịch vụ
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Mã dịch vụ",
        dataIndex: "serviceCode",
        key: "serviceCode",
    },
    {
        title: "Tên dịch vụ",
        dataIndex: "serviceName",
        key: "serviceName",
    },
    {
        title: "Ngày bắt đầu",
        dataIndex: "startDate",
        key: "startDate",
    },
    {
        title: "Ngày kết thúc",
        dataIndex: "endDate",
        key: "endDate",
    },
    {
        title: "Giá",
        dataIndex: "price",
        key: "price",
    },
    {
        title: "Đơn vị",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "Người thực hiện",
        dataIndex: "performedBy",
        key: "performedBy",
    },
];

const _dataSource = [
    {
        key: 1,
        serviceCode: "S001",
        serviceName: "Dịch vụ A",
        startDate: "2024-08-01",
        endDate: "2024-08-31",
        price: 500000,
        unit: "Dịch vụ",
        status: "Hoạt động",
        performedBy: "Nguyễn Văn A",
    },
    {
        key: 2,
        serviceCode: "S002",
        serviceName: "Dịch vụ B",
        startDate: "2024-08-05",
        endDate: "2024-09-05",
        price: 750000,
        unit: "Dịch vụ",
        status: "Đang triển khai",
        performedBy: "Trần Thị B",
    },
    {
        key: 3,
        serviceCode: "S003",
        serviceName: "Dịch vụ C",
        startDate: "2024-08-10",
        endDate: "2024-09-10",
        price: 1000000,
        unit: "Dịch vụ",
        status: "Hoàn tất",
        performedBy: "Lê Văn C",
    },
];

function ServiceTable(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default ServiceTable;
