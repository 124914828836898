import QueryString from "qs"
import http from "../index"
import {
  apiAssigned,
  apiCallLog,
  apiExportOrder, apiGetAllPermission,
  apiGetDistrict,
  apiGetProvince,
  apiGetRolePermissions,
  apiGetStatistics,
  apiGetWard,
  apiListPersonnel,
  apiOrderUpdateStatus,
  apiTotalPrice,
} from "./urls"

// const getAllowanceHistory = body =>
//   http.get(apiAllowanceHistory + `?${QueryString.stringify(body)}`)
// const salaryHistoryCreate = body => http.post(apiSalaryHistoryCreate, body)
// const salaryHistoryUpdate = (id, body) =>
//   http.put(apiSalaryHistoryUpdate(id), body)
// const salaryHistoryDelete = params =>
//   http.delete(apiSalaryHistoryDelete, { params })
// const getContractList = params => http.get(apiGetListContract, { params })
const postAssigned = params => http.post(apiAssigned, params)
const getListPersonnel = params => http.get(apiListPersonnel, {params})
const exportOrder = params => http.get(apiExportOrder, {params})
const totalPrice = params => http.post(apiTotalPrice, params)
const orderUpdateStatus = params => http.put(apiOrderUpdateStatus, params)
const callLog = body => http.post(apiCallLog, body)
const getProvince = () => http.get(apiGetProvince)
const getDistrict = params => http.get(apiGetDistrict, {params})
const getWard = params => http.get(apiGetWard, {params})
const getStatistics = params => http.get(apiGetStatistics, {params})
const getRolePermissions = id => http.get(apiGetRolePermissions(id))
const getAllPermission = () => http.get(apiGetAllPermission)

const CRMService = {
  postAssigned,
  getListPersonnel,
  exportOrder,
  totalPrice,
  orderUpdateStatus,
  callLog,
  getProvince,
  getDistrict,
  getWard,
  getStatistics,
  getRolePermissions,
  getAllPermission
}

export default CRMService

