import { Col, Input, Row } from 'antd';
import React from 'react';
import Button from 'src/components/MyButton/Button';
import SvgIcon from 'src/components/SvgIcon';
import TableCustom from 'src/components/Table/CustomTable';
import { InputSearchStyle } from '../../../HumanResource/Work/Task/styled';
import { WrapperStyle } from "src/pages/ADMIN/HumanResource/styled";
import { ToolBarStyle } from '../../Setting/common/styled';

const column = [
    {
        title: 'TT',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Tiêu đề',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Tài khoản nợ',
        children: [
            {
                title: 'Quỹ',
                children: [
                    {
                        title: 'VNĐ',
                        dataIndex: 'debitCashVND',
                        key: 'debitCashVND',
                    },
                    {
                        title: 'Ngoại tệ',
                        dataIndex: 'debitCashForeign',
                        key: 'debitCashForeign',
                    },
                ],
            },
            {
                title: 'Ngân hàng',
                children: [
                    {
                        title: 'VNĐ',
                        dataIndex: 'debitBankVND',
                        key: 'debitBankVND',
                    },
                    {
                        title: 'Ngoại tệ',
                        dataIndex: 'debitBankForeign',
                        key: 'debitBankForeign',
                    },
                ],
            },
        ],
    },
    {
        title: 'Tài khoản có',
        dataIndex: 'creditAccount',
        key: 'creditAccount',
    },
];

const dataSource = [
    {
        key: '1',
        index: 1,
        title: 'Thu tiền khách hàng',
        debitCashVND: '1111',
        debitCashForeign: '1112',
        debitBankVND: '1121',
        debitBankForeign: '1122',
        creditAccount: '131,1388,3388',
    },
    {
        key: '2',
        index: 2,
        title: 'Rút tiền gửi ngân hàng nhập quỹ tiền ...',
        debitCashVND: '1111',
        debitCashForeign: '1112',
        debitBankVND: '1121',
        debitBankForeign: '1122',
        creditAccount: '1121,1122',
    },
    {
        key: '3',
        index: 3,
        title: 'Hoàn thuế GTGT',
        debitCashVND: '1111',
        debitCashForeign: '1112',
        debitBankVND: '1121',
        debitBankForeign: '1122',
        creditAccount: '1331,1332,711',
    },
    {
        key: '4',
        index: 4,
        title: 'Hoàn tạm ứng',
        debitCashVND: '1111',
        debitCashForeign: '1112',
        debitBankVND: '1121',
        debitBankForeign: '1122',
        creditAccount: '141',
    },
    {
        key: '5',
        index: 5,
        title: 'Thu vay vốn',
        debitCashVND: '1111',
        debitCashForeign: '1112',
        debitBankVND: '1121',
        debitBankForeign: '1122',
        creditAccount: '341',
    },
    {
        key: '6',
        index: 6,
        title: 'Thu khác',
        debitCashVND: '1111',
        debitCashForeign: '1112',
        debitBankVND: '1121',
        debitBankForeign: '1122',
        creditAccount: '1111,131,1386,331,3388,5111,515,6422,711',
    },
];

const Receipts = () => {
    return (
        <WrapperStyle>
            <ToolBarStyle>
                <div className="left-side">PHIẾU CHI</div>
                <div className="right-side">
                    <Button btnType="primary1" onClick={() => { }} size="small" >
                        Tạo mới
                    </Button>
                </div>
            </ToolBarStyle>
            <div style={{ display: "flex", justifyContent: "end",paddingBottom:"8px" }}>
                <InputSearchStyle >
                    <Input
                        className="custom-input"
                        maxLength={50}
                        placeholder="Tìm kiếm"
                        onChange={(e) => { }}
                        onPressEnter={(e) => { }}
                    />
                    <div className="custom-icon-search">
                        <SvgIcon name="searchStatistic" onClick={() => { }} />
                    </div>
                </InputSearchStyle>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <TableCustom
                        isPrimary
                        columns={column}
                        dataSource={dataSource}
                        showPagination
                        rowKey={"key"}
                        footerLeft={<div className="d-flex mt-20" />}
                        widthScroll={1200}
                        textEmpty="Không có dữ liệu!"
                    />
                </Col>
            </Row>
        </WrapperStyle>
    );
}

export default Receipts;
