import React, { useState } from "react"
import PropTypes from "prop-types"
import { Popover } from "antd"

// Components
import IconBase from "src/components/IconBase"

// Styled
import { ContentStyle } from './styled'

function Index({ iconName, children }) {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = visible => {
    setVisible(visible)
  }

  const content = (
    <ContentStyle>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: (e) => {
            child.props.onClick && child.props.onClick(e);
            setVisible(false);
          },
        })
      )}
    </ContentStyle>
  )

  return (
    <Popover
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement="left"
    >
      <IconBase name={iconName} />
    </Popover>
  )
}

Index.propTypes = {
  iconName: PropTypes.string,
  children: PropTypes.node
}

Index.defaultProps = {
  iconName: 'threeDots',
  children: null,
}

export default Index