import styled from "styled-components";

const ModalCreateStyle = styled.div`
.boxInputCenter{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

`
export { ModalCreateStyle}
const StyleModalCalling = styled.div`

 #phoneui {
    width: 100% !important;
    border: 2px solid rgb(24, 144, 255) !important;
    background-color: rgb(240, 242, 245) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px !important;
    min-height: 500px !important;
    display: flex !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    height: 100% !important;
    padding-bottom: 40px !important;
  }
  .phone {
    margin: 10px auto;
    width: 100%;
    /* border: 2px solid #1890ff; */
    border-radius: 8px;
}
.topcorner2 {
    position: relative !important;
    top: 30px !important;
    right: 3px !important;
}
.phone .call-display .call-info {
    background: rgb(71, 78, 84);
    margin-top: 1rem !important;
    margin-bottom: 9px !important;
    padding: 5rem 7.5rem !important;
    border-radius: 1rem !important;
    box-shadow: rgb(38, 42, 45) 0px 0px 0.75rem 0.1rem !important;
}
.btn-group>.btn:first-child {
    margin-left: 0px !important;
    margin-right: 10px !important;
    margin-top: 7px;
}


/* Butoon */
.topcorner {
    margin: 3px 1px 0px 0px !important;
    position: absolute;
    top: 1px;
    right: 3px;
    width: 40px;
    height: 30px;
    background-color: #007bff; /* Màu nền */
    color: white; /* Màu chữ */
    border: none; /* Không viền */
    border-radius: 5px; /* Bo tròn góc */
    display: flex; /* Căn giữa nội dung */
    align-items: center; /* Căn giữa theo chiều dọc */
    justify-content: center; /* Căn giữa theo chiều ngang */
    cursor: pointer; /* Con trỏ chuột khi hover */
    transition: background-color 0.3s, transform 0.2s; /* Hiệu ứng chuyển tiếp */
}

.topcorner:hover {
    background-color: #0056b3; /* Màu nền khi hover */
    transform: scale(1.05); /* Phóng to một chút khi hover */
}

.topcorner:active {
    transform: scale(0.95); /* Co lại khi click */
}

`
export { StyleModalCalling}



const FinalWorkModalStyle = styled.div`
.boxInputCenter{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

`
export { FinalWorkModalStyle}

const ImportStyled = styled.div`
.boxInputCenter{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

`
export { ImportStyled}


const CustomTitleModalStyle = styled.div`
.BoxTitle{
    color: #0091c7;
    padding: 10px 0 5px;
    font-weight: 700;
    text-align: left;
    width: 15%;
    margin-bottom: 15px;
}
.contenTitle{
    padding: 3px 0;
    border-bottom: 1px solid #0091c7;
}

`
export { CustomTitleModalStyle}