import styled from "styled-components"

const ContentStyle = styled.div`
  div {
    display: flex;
    cursor: pointer;
    margin: 5px 0;
    span:first-child {
      margin-right: 10px;
    }
  }
`

export { ContentStyle }