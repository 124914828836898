import styled from "styled-components"

export const PersonStyle = styled.div`
  padding-bottom: 20px;
  b {
    margin-bottom: 10px;
    display: block;
  }
  .solid-border {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 12px 0;
    margin-bottom: 10px;
    .ant-form-item-label label {
      font-weight: unset;
    }
  }

  .ant-tooltip.ant-tooltip-placement-top {
    display: none;
  }
  .ant-upload-list-item.ant-upload-list-item-error {
    border: 1px solid #d9d9d9 !important;
  }

  .ant-select-show-arrow.ant-select-disabled .ant-select-selector {
    background-color: #ffffff !important;
  }
  .collap-block {
    display: flex;
    justify-content: space-between;
    padding-right: 28px;
    .tranf {
      transform: rotate(270deg) !important;
    }
    .collapIcon {
      transform: rotate(90deg);
      cursor: pointer;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hidden-block {
    height: 0 !important;
    overflow: hidden;
  }
`

const WrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`

const BlockInfo = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;
  margin: 5px 0 20px;
`

const TextTile = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

const RowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
  }
  .ant-form-item-explain {
    white-space: normal;
    word-wrap: break-word;
  }
`

const RowFieldStyle = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px;
  white-space: nowrap;
  .row-col-1 {
    display: flex;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

const LabelStyle = styled.div`
  flex: 1;
  margin-top: 5px;
  height: 100%;
`

const FooterStyle = styled.div`
  position: absolute;
  display: flex;
  z-index: 10;
  border-radius: 8px;
  width: 100%;
  right: 0;
  bottom: 0;
  justify-content: flex-end;
  padding: 15px 50px;
  background-color: #ffffff;
`

export {
  WrapperStyle,
  BlockInfo,
  TextTile,
  RowStyle,
  RowFieldStyle,
  LabelStyle,
  FooterStyle,
}
