import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Cột bảng hàng bán trả lại
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Mã phiếu",
        dataIndex: "voucherCode",
        key: "voucherCode",
    },
    {
        title: "Ngày bán",
        dataIndex: "saleDate",
        key: "saleDate",
    },
    {
        title: "Mã hàng",
        dataIndex: "itemCode",
        key: "itemCode",
    },
    {
        title: "Tên hàng",
        dataIndex: "itemName",
        key: "itemName",
    },
    {
        title: "Số lượng trả lại",
        dataIndex: "quantityReturned",
        key: "quantityReturned",
    },
    {
        title: "Đơn giá",
        dataIndex: "unitPrice",
        key: "unitPrice",
    },
    {
        title: "Tổng giá trị",
        dataIndex: "totalValue",
        key: "totalValue",
    },
    {
        title: "Người thực hiện",
        dataIndex: "performedBy",
        key: "performedBy",
    },
];

const _dataSource = [
    {
        key: 1,
        voucherCode: "R001",
        saleDate: "2024-08-01",
        itemCode: "ITEM001",
        itemName: "Hàng A",
        quantityReturned: 10,
        unitPrice: 1000,
        totalValue: 10000,
        performedBy: "Nguyễn Văn A",
    },
    {
        key: 2,
        voucherCode: "R002",
        saleDate: "2024-08-02",
        itemCode: "ITEM002",
        itemName: "Hàng B",
        quantityReturned: 5,
        unitPrice: 2000,
        totalValue: 10000,
        performedBy: "Trần Thị B",
    },
    {
        key: 3,
        voucherCode: "R003",
        saleDate: "2024-08-03",
        itemCode: "ITEM003",
        itemName: "Hàng C",
        quantityReturned: 8,
        unitPrice: 1500,
        totalValue: 12000,
        performedBy: "Lê Văn C",
    },
];

function ReturnedGoods(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default ReturnedGoods;
