import PropTypes from "prop-types"
import cn from "src/lib/classnames"
import { ModalWrapper } from "./styled"

import styles from "./styles.module.scss"
import "./style.scss"
const SIZE = {
  large: 1024,  
  medium: 768,    
  small: 480,   
}

export default function CustomModal(props) {
  const { children, className, tilteStart, hiddenScroll, size, } = props

  return (
    <ModalWrapper
      width={SIZE[size]}
      style={{ top: 10 }}
      {...props}
      className={cn(className, { [styles.titleFlexStart]: tilteStart })}
      hiddenScroll={hiddenScroll}
      maskTransitionName=""
    >
      {children}
    </ModalWrapper>
  )
}

CustomModal.propTypes = {
  tilteStart: PropTypes.bool,
  className: PropTypes.string,
  hiddenScroll: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(SIZE)),
}

CustomModal.defaultProps = {
  tilteStart: true,
  hiddenScroll: false,
  className: "",
  size:"large" ,
}
