import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Loại phiếu chi
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Số phiếu",
        dataIndex: "voucherNumber",
        key: "voucherNumber",
    },
    {
        title: "Từ ngân hàng",
        dataIndex: "fromBank",
        key: "fromBank",
    },
    {
        title: "Đến ngân hàng",
        dataIndex: "toBank",
        key: "toBank",
    },
    {
        title: "Tổng tiền",
        dataIndex: "totalAmount",
        key: "totalAmount",
    },
    {
        title: "Ngày hạch toán",
        dataIndex: "accountingDate",
        key: "accountingDate",
    },
    {
        title: "Ngày chứng từ",
        dataIndex: "documentDate",
        key: "documentDate",
    },
    {
        title: "Ngày Hạch toán",
        dataIndex: "accountingDate",
        key: "accountingDate",
    },
];

const _dataSource = [
    {
        key: 1,
        voucherNumber: "V001",
        fromBank: "Ngân hàng A",
        toBank: "Ngân hàng B",
        totalAmount: 5000000,
        accountingDate: "2024-08-01",
        documentDate: "2024-07-30",
    },
    {
        key: 2,
        voucherNumber: "V002",
        fromBank: "Ngân hàng C",
        toBank: "Ngân hàng D",
        totalAmount: 10000000,
        accountingDate: "2024-08-02",
        documentDate: "2024-07-31",
    },
    {
        key: 3,
        voucherNumber: "V003",
        fromBank: "Ngân hàng E",
        toBank: "Ngân hàng F",
        totalAmount: 7500000,
        accountingDate: "2024-08-03",
        documentDate: "2024-08-01",
    },
];

function IndividualCartificate(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default IndividualCartificate;
