import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { Col, Form, message, Row, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Components
import Button from "src/components/MyButton/Button";
import CRMService from "src/services/CRMService";
import Index from "../../../Finance/Report"
import BasicProfile from "../../../../../components/CardInfo/BasicProfile"

const ModalAssigner = (props) => {
  const { onCancel, open, getData, dataSelected, status, setSelectedRowKeys } = props;

  const [assignerList, setAssignerList] = useState([]);

  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  // Gọi API để lấy danh sách người phụ trách
  const fetchPersonnelList = async () => {
    setLoading(true);
    try {
      const response = await CRMService.getListPersonnel();
      // console.log("personnelData",response.data)
      if (!response) {
        message.error("Không thể lấy danh sách người phụ trách!");
      } else {
        const formattedData = response?.data.map(person => ({
          id: person.ID,
          name: person.name
        }));
        setAssignerList(formattedData); 
      }
    } catch (error) {
    
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchPersonnelList(); 
  }, []);

  const handleFormChange = (changedValues, allValues) => {
    const hasData = Object.values(allValues).some((value) => value); 
    setDisableBtn(!hasData); 
  };

  const handleSave = async () => {
    const values = await form.getFieldsValue();
    setLoading(true);
    try {
      const ids = open[0] === true ? [...dataSelected] : [...open, ...dataSelected];
      const response = await CRMService.postAssigned({
        ids,
        personnelId: values?.assigner,
      });

      if (response?.isError) {
        message.error("Lưu thất bại!");
      } else {
        onCancel()
        setSelectedRowKeys([])
        getData(1, 50, status)
        message.success("Lưu thành công!");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra!");
      console.log('error', error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onValuesChange={handleFormChange}
      >
        <Row>
          <Col span={24} className="pr-16">
            <Form.Item label="Chọn người phụ trách:" name="assigner">
              <Select placeholder="Chọn người phụ trách" allowClear>
                {assignerList.map((assigner) => (
                  <Select.Option key={assigner.id} value={assigner.id}>
                    {assigner.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="d-flex justify-content-flex-end pt-40">
              <Button
                btnType="back"
                className="btn-hover-shadow mr-15"
                onClick={onCancel}
              >
                Hủy bỏ
              </Button>
              <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={handleSave}
                disabled={disableBtn}
              >
                {loading ? <LoadingOutlined /> : "Lưu lại"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ModalAssigner.propTypes = {
  open: PropTypes.array,
  getData: PropTypes.func,
}

ModalAssigner.defaultProps = {
  open: [],
  getData: () => {},
}

export default ModalAssigner;
