import { Tabs } from "antd"
import { StyleTabsCustom } from "./styled" //

const TabsCustom = (props) => {
  const { items, defaultActiveKey, type, onchange, activeKey } = props
  return (
    <StyleTabsCustom>
      <Tabs
        type={type}
        defaultActiveKey={defaultActiveKey}
        items={items}
        onChange={onchange ? onchange : null}
        activeKey={activeKey}
        {...props}
      />
    </StyleTabsCustom>
  )
}

export default TabsCustom

