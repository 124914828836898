import React, { useState } from "react"

import TabsCustom from "src/components/TabsCustom"
import ToolBarCustom from "src/components/ToolBarCustom"

import { WrapperStyle } from "../../Assets/common/styled"
import { StyleTabsCustom } from "src/components/TabsCustom/styled"
import ReportHRComponent from "./Component/ReportHRComponent"

function RecruitmentRequirements(props) { 
  const [tab, setTab] = useState("report")
  const items = [
    {
      key: "1",
      label: <div className="text-tile-tabs">Nhân Sự</div>,
      children: <ReportHRComponent />,
    },
    {
      key: "2",
      label: <div className="text-tile-tabs">Bảo hiểm</div>,
      children: <ReportHRComponent />,
    },
    {
      key: "3",
      label: <div className="text-tile-tabs">Hợp đồng</div>,
      children: <ReportHRComponent />,
    },
    {
      key: "4",
      label: <div className="text-tile-tabs">Tuyển dụng</div>,
      children: <ReportHRComponent />,
    },
 
   
  ]

  const onChangeTabs = nameTab => {
    setTab(nameTab)
  }

  return (
    <WrapperStyle>
      <ToolBarCustom
        title={"BÁO CÁO TỔNG QUÁT"}
        onCreateNew={() => {}}
      />
      <StyleTabsCustom>
        <TabsCustom
          items={items}
          defaultActiveKey="report"
          type="1"
          onchange={onChangeTabs}
        />
      </StyleTabsCustom>
    </WrapperStyle>
  )
}

export default RecruitmentRequirements

