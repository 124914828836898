import styled from "styled-components"

const WrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`

const BlockInfo = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 8px;
    margin: 5px 0 20px;
`

const TextTile = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

const RowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
  }
  .ant-form-item-explain {
      white-space: normal;
      word-wrap: break-word;
  }
`

const RowFieldStyle = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px;
  white-space: nowrap;
  .row-col-1 {
    display: flex;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

const LabelStyle = styled.div`
  flex: 1;
  margin-top: 5px;
  height: 100%;
  white-space: normal;
`

const FooterStyle = styled.div`
    position: absolute;
    display: flex;
    z-index: 10;
    border-radius: 8px;
    width: 100%;
    right: 0;
    bottom: 0;
    justify-content: flex-end;
    padding: 15px 50px;
    background-color: #ffffff;
`

export { WrapperStyle, BlockInfo, TextTile, RowStyle, RowFieldStyle, LabelStyle, FooterStyle }

