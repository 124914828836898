import styled from "styled-components"

const LayoutStyledContenAdmin = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0px;
  .content {
    width: 100%;
  }
`
const LayoutContenAdmin = props => {
  return (
    <LayoutStyledContenAdmin>
      <div className="content" {...props}>
        {props?.children}
      </div>
    </LayoutStyledContenAdmin>
  )
}

export default LayoutContenAdmin
