import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const LoadingText = ({ text = '' }) => {
    const [dots, setDots] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots + 1) % 4);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const dotDisplay = '.'.repeat(dots);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ margin: 0 }}>{text}</Text>
            <Text style={{ margin: 0 }}>{dotDisplay}</Text>
        </div>
    );
};

export default LoadingText;
