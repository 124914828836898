import styled from 'styled-components';

const WrapperStyle = styled.div`
  margin-top: 20px;
`
const ToolBarStyle = styled.div`
    display: flex;
    padding: 10px 0 10px;
    justify-content: space-between;
    align-items: center;
    .left-side {
        font-size: 20px;
        font-weight: bold;
        color: #01638D;
        min-width: auto;
        &:hover {
            cursor: pointer;
        }
    }
    .right-side {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }`

const StyleTabsCustom = styled.div`
 .text-tile-tabs {
    font-size: 20px ;
}
`

const InputSearchStyle = styled.div`
    display: flex;
    align-items: center;
    min-width: 100px;
    padding: 0 8px;
    background-color: #fff;
    max-width: 400px;
    border: 1px solid #A9A9A9;
    border-radius: 20px;
    .custom-input {
        border: none; /* Bỏ border */
        border-radius: 20px; /* Bỏ border-radius nếu có */
        box-shadow: none; /* Bỏ shadow nếu có */
    }

    .custom-input:focus {
        box-shadow: none; /* Bỏ shadow khi input được focus */
        border: none; /* Bỏ border khi input được focus */
    }
    
    .custom-icon-search {
        cursor: pointer;
        border-radius: 20px;
        //background-color: #18C0F6;
        & > svg {
            width: 10px;
            height: 10px;
        }
    }
`

export { WrapperStyle, ToolBarStyle, StyleTabsCustom, InputSearchStyle };
