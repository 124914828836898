import styled from "styled-components"

const ToolbarStyle = styled.div`
  display: flex;
  padding: 10px 0 10px;
  justify-content: space-between;
  align-items: center;
  .left-side {
    font-size: 20px;
    font-weight: bold;
    color: #01638d;
    min-width: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .right-side {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
`
const ActionContactStyle = styled.div`
  ul {
    list-style: none;
    .row-icon {
      padding: 4px;
      cursor: pointer;
      border-radius: 5px;
      :hover {
        background: #f6f6f6;
      }
      .anticon {
        color: #0091c7;
        margin-right: 4px;
      }
    }
  }
`

export { ToolbarStyle, ActionContactStyle }

