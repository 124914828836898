import styled from "styled-components"
import TableCustom from "../../../components/Table/CustomTable"
import { Pagination } from "antd"

const WrapperStyle = styled.div`
  margin-top: 20px;
`

const HrToolbarStyle = styled.div`
  display: flex;
  padding: 10px 0 10px;
  justify-content: space-between;
  align-items: center;
  .left-side {
    font-size: 20px;
    font-weight: bold;
    color: #01638d;
    min-width: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .right-side {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
`

const TableCustomStyle = styled(TableCustom)`
  margin-top: 10px;
  //.ant-table-body {
  //    overflow-y: hidden; /* Ẩn thanh cuộn dọc của bảng */
  //}
  .ant-table-content {
    overflow-y: scroll; /* Hiển thị vùng cuộn dọc bên trong bảng */
  }
  .ant-table-body::-webkit-scrollbar {
    display: none; /* Ẩn thanh cuộn dọc trong WebKit browsers (Chrome, Safari) */
  }
`

const PaginationStyle = styled(Pagination)`
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
`

const FilterStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InputSearchStyle = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  padding: 0 8px;
  background-color: #fff;
  max-width: 400px;
  border: 1px solid #a9a9a9;
  border-radius: 20px;
  .custom-input {
    border: none; /* Bỏ border */
    border-radius: 20px; /* Bỏ border-radius nếu có */
    box-shadow: none; /* Bỏ shadow nếu có */
  }

  .custom-input:focus {
    box-shadow: none; /* Bỏ shadow khi input được focus */
    border: none; /* Bỏ border khi input được focus */
  }

  .custom-icon-search {
    cursor: pointer;
    border-radius: 20px;
    //background-color: #18C0F6;
    & > svg {
      width: 10px;
      height: 10px;
    }
  }
`
const BlockInfoStyle = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;
  margin: 5px 0 20px;
`

const TextTileStyle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export {
  BlockInfoStyle,
  TextTileStyle,
  WrapperStyle,
  HrToolbarStyle,
  TableCustomStyle,
  PaginationStyle,
  FilterStyle,
  InputSearchStyle,
}

