import Button from "src/components/MyButton/Button"
const { ToolbarStyle } = require("./styled")

const ToolBarCustom = ({ title = "Danh sách liên hệ", onCreateNew, disableBtn  = false, status = true }) => {
  return (
    <ToolbarStyle>
      <div className="left-side">{title}</div>
      {status && (
        <div className="right-side">
          <Button
            btnType="primary1"
            onClick={() => onCreateNew && onCreateNew(true)}
            size="small"
            disabled={disableBtn}
          >
            Tạo mới
          </Button>
        </div>
      )}
    </ToolbarStyle>
  )
}

export default ToolBarCustom

