import React, { memo, lazy, Suspense } from "react"
import PropTypes from "prop-types"

const Icons = {
  comment: lazy(() => import("./Icons/Comment")),
  pin: lazy(() => import("./Icons/Pin")),
  threeDots: lazy(() => import("./Icons/ThreeDots")),
  eye: lazy(() => import("./Icons/Eye")),
  delete: lazy(() => import("./Icons/Delete")),
  edit: lazy(() => import("./Icons/Edit")),
  smsMessage: lazy(() => import("./Icons/SmsMessage")),
  refresh: lazy(() => import("./Icons/Refresh")),
  addUser: lazy(() => import("./Icons/AddUser")),
  favorite: lazy(() => import("./Icons/Favorite")),
  phone: lazy(() => import("./Icons/Phone")),
  calendar: lazy(() => import("./Icons/Calendar")),
  up: lazy(() => import("./Icons/Up")),
  next: lazy(() => import("./Icons/Next")),
  searchDetail: lazy(() => import("./Icons/SearchDetail")),
  people: lazy(() => import("./Icons/People")),
  zalo: lazy(() => import("./Icons/Zalo")),
  mic: lazy(() => import("./Icons/Mic")),
  muteMic: lazy(() => import("./Icons/MuteMic")),
}

const SIZE = {
  tiny: 14,
  smallest: 18,
  small: 20,
  medium: 22,
  large: 24,
  larger: 32,
  huge: 40,
}

function IconBase(props) {
  const {
    name,
    size = "small",
    onClick,
    color = "#01638D",
    style = {},
    className,
    viewBox,
  } = props
  const Icon = Icons[name]
  return (
    <Suspense fallback={null}>
      <span onClick={onClick} className={className}>
        <Icon
          width={SIZE[size]}
          height={SIZE[size]}
          color={color}
          style={style}
          viewBox={viewBox}
        />
      </span>
    </Suspense>
  )
}

IconBase.propTypes = {
  name: PropTypes.oneOf(Object.keys(Icons)),
  size: PropTypes.oneOf(Object.keys(SIZE)),
  color: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  viewBox: PropTypes.string,
  onClick: PropTypes.func,
}

IconBase.defaultProps = {
  className: "",
  onClick: () => {},
}

export default memo(IconBase)

