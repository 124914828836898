import React, { useEffect, useState } from "react";
import { Space } from "antd";
import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Cột bảng vật tư hàng hóa
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Mã vật tư",
        dataIndex: "itemCode",
        key: "itemCode",
    },
    {
        title: "Tên vật tư",
        dataIndex: "itemName",
        key: "itemName",
    },
    {
        title: "Đơn vị",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Số lượng tồn",
        dataIndex: "quantityInStock",
        key: "quantityInStock",
    },
    {
        title: "Đơn giá",
        dataIndex: "unitPrice",
        key: "unitPrice",
    },
    {
        title: "Tổng giá trị",
        dataIndex: "totalValue",
        key: "totalValue",
    },
    {
        title: "Ngày nhập",
        dataIndex: "dateReceived",
        key: "dateReceived",
    },
];

const _dataSource = [
    {
        key: 1,
        itemCode: "MT001",
        itemName: "Vật tư A",
        unit: "Cái",
        quantityInStock: 100,
        unitPrice: 20000,
        totalValue: 2000000,
        dateReceived: "2024-07-15",
    },
    {
        key: 2,
        itemCode: "MT002",
        itemName: "Vật tư B",
        unit: "Hộp",
        quantityInStock: 50,
        unitPrice: 35000,
        totalValue: 1750000,
        dateReceived: "2024-07-20",
    },
    {
        key: 3,
        itemCode: "MT003",
        itemName: "Vật tư C",
        unit: "Kg",
        quantityInStock: 200,
        unitPrice: 15000,
        totalValue: 3000000,
        dateReceived: "2024-07-25",
    },
];

function GoodsAndMaterialsComponent(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default GoodsAndMaterialsComponent;
