import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Cột bảng phiếu nhập kho
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Mã phiếu",
        dataIndex: "voucherCode",
        key: "voucherCode",
    },
    {
        title: "Ngày nhập",
        dataIndex: "entryDate",
        key: "entryDate",
    },
    {
        title: "Mã vật tư",
        dataIndex: "itemCode",
        key: "itemCode",
    },
    {
        title: "Tên vật tư",
        dataIndex: "itemName",
        key: "itemName",
    },
    {
        title: "Số lượng nhập",
        dataIndex: "quantityIn",
        key: "quantityIn",
    },
    {
        title: "Đơn giá",
        dataIndex: "unitPrice",
        key: "unitPrice",
    },
    {
        title: "Tổng giá trị",
        dataIndex: "totalValue",
        key: "totalValue",
    },
    {
        title: "Người thực hiện",
        dataIndex: "performedBy",
        key: "performedBy",
    },
];

const _dataSource = [
    {
        key: 1,
        voucherCode: "INV001",
        entryDate: "2024-08-01",
        itemCode: "MT001",
        itemName: "Vật tư A",
        quantityIn: 100,
        unitPrice: 5000,
        totalValue: 500000,
        performedBy: "Nguyễn Văn A",
    },
    {
        key: 2,
        voucherCode: "INV002",
        entryDate: "2024-08-02",
        itemCode: "MT002",
        itemName: "Vật tư B",
        quantityIn: 200,
        unitPrice: 3000,
        totalValue: 600000,
        performedBy: "Trần Thị B",
    },
    {
        key: 3,
        voucherCode: "INV003",
        entryDate: "2024-08-03",
        itemCode: "MT003",
        itemName: "Vật tư C",
        quantityIn: 150,
        unitPrice: 4000,
        totalValue: 600000,
        performedBy: "Lê Văn C",
    },
];

function WarehouseEntry(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default WarehouseEntry;
