import React, { useEffect, useState } from "react";
import { Space } from "antd";

import TableCustom from "src/components/Table/CustomTable";
import ButtonCircle from "src/components/MyButton/ButtonCircle";
import CB1 from "src/components/Modal/CB1";

// Cột phiếu xuất kho
const column = [
    {
        title: "TT",
        dataIndex: "index",
        key: "index",
        render: (_, record, i) => {
            return <>{i + 1}</>;
        },
    },
    {
        title: "Số phiếu xuất kho",
        dataIndex: "voucherNumber",
        key: "voucherNumber",
    },
    {
        title: "Ngày xuất kho",
        dataIndex: "issueDate",
        key: "issueDate",
    },
    {
        title: "Kho xuất",
        dataIndex: "fromWarehouse",
        key: "fromWarehouse",
    },
    {
        title: "Kho đến",
        dataIndex: "toWarehouse",
        key: "toWarehouse",
    },
    {
        title: "Tổng số lượng",
        dataIndex: "totalQuantity",
        key: "totalQuantity",
    },
    {
        title: "Tổng giá trị",
        dataIndex: "totalValue",
        key: "totalValue",
    },
    {
        title: "Người thực hiện",
        dataIndex: "executor",
        key: "executor",
    },
];

const _dataSource = [
    {
        key: 1,
        voucherNumber: "P001",
        issueDate: "2024-08-01",
        fromWarehouse: "Kho A",
        toWarehouse: "Kho B",
        totalQuantity: 100,
        totalValue: 5000000,
        executor: "Nguyễn Văn A",
    },
    {
        key: 2,
        voucherNumber: "P002",
        issueDate: "2024-08-02",
        fromWarehouse: "Kho C",
        toWarehouse: "Kho D",
        totalQuantity: 150,
        totalValue: 7500000,
        executor: "Trần Thị B",
    },
    {
        key: 3,
        voucherNumber: "P003",
        issueDate: "2024-08-03",
        fromWarehouse: "Kho E",
        toWarehouse: "Kho F",
        totalQuantity: 200,
        totalValue: 10000000,
        executor: "Lê Văn C",
    },
];

function DeliveryBill(props) {
    const [dataSource, setDataSource] = useState(_dataSource);
    const [dataSelected, setDataSelected] = useState([]);

    useEffect(() => {
        // Effect logic here if needed
    }, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDataSelected(selectedRows);
        },
    };

    return (
        <div>
            {!!dataSelected.length && (
                <ButtonCircle
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    title="Xóa hàng đã chọn"
                    iconName="delete-outline"
                    onClick={() => CB1({
                        title: `Bạn có chắc chắn muốn xoá <strong>${dataSelected.length}</strong> bản ghi không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                            // Logic to delete selected rows
                            close();
                        },
                    })}
                />
            )}
            <TableCustom
                isPrimary
                columns={column}
                dataSource={dataSource}
                showPagination
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                footerLeft={<div className="d-flex mt-20" />}
                widthScroll={1200}
                textEmpty="Không có dữ liệu!"
            />
        </div>
    );
}

export default DeliveryBill;
