const QUAN_LY_TAI_KHOAN = "/system_management"
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  TEST: "/test",
  HOME: "/",
  DANG_NHAP: "/login",
  DANG_KY: "/register",
  DOI_MAT_KHAU: "/change_password",
  ACCOUNT: "/account",
  //ANONYMOUS
  PROCEDURE: "/procedure",
  // ADMIN
  QUAN_LY_TAI_KHOAN: "/system_management",
  QUAN_LY_KE_TOAN: `${QUAN_LY_TAI_KHOAN}/management_of_accounting_system`,
  LIEN_HE: `${QUAN_LY_TAI_KHOAN}/contact`,
  CO_HOI: `${QUAN_LY_TAI_KHOAN}/opportunity`,
  QUAN_LY_NHAN_SU: `${QUAN_LY_TAI_KHOAN}/hrm`,
  SETTING_PERMISSIONS: `${QUAN_LY_TAI_KHOAN}/setting_permissions`,

  // HR - Nhân sự
  HR: "/hr",
  //--Hồ sơ nhân sự
  HR_FILES: "/hr/files",
  //--Hợp đồng
  HR_CONTRACT: "/hr/contract",
  //--Bảo hiểm
  HR_INSURANCE: "/hr/insurance",
  //---Chấm công
  HR_TIMEKEEPING: "/hr/timekeeping",
  //--Bảng lương
  HR_PAYROLL: "/hr/payroll",
  //--Thưởng phạt
  HR_REWARDS: "/hr/rewards",
  //--Đánh giá
  HR_EVALUATION: "/hr/evaluation",
  HR_EVALUATION_SYNTHETIC: "/hr/evaluation/synthetic",
  //--Tuyển dụng
  HR_HIRING: "/hr/hiring",
  // yêu cầu tuyển dụng
  HR_JOB_REQUESTS: "/hr/job-requests",
  // hồ sơ ứng viên
  HR_CANDIDATE_PROFILES: "/hr/candidate-profiles",
  // lịch phỏng vấn
  HR_INTERVIEW_SCHEDULES: "/hr/interview-schedules",
  // Work - Công việc
  WORK_TASK: "hr/work/task",
  // Báo cáo
  WORK_TASK_JOB: "hr/work/task/Job",
  WORK_TASK_STAFF: "hr/work/task/staff",
  WORK_TASK_DEPARTMENT: "hr/work/task/Department",
  // DOCUMENT - Tài liệu
  DOCUMENT: "hr/document",
  // Applications - Đơn từ
  APPLICATION: "hr/application",
  //--Đơn xin nghỉ
  APPLICATION_LEAVE: "hr/application/leave",
  //--Đơn xin vắng mặt
  APPLICATION_ABSENCE: "hr/application/absence",
  //--Đơn xin làm thêm
  APPLICATION_OVERTIME: "hr/application/overtime",
  //--Đơn thuyên chuyển
  APPLICATION_TRANSFER: "hr/application/transfer",
  //--Đơn thôi việc
  APPLICATION_RESIGNATION: "hr/application/resignation",

  // Target - Mục tiêu
  TARGET: "hr/target",

  //--Báo cáo
  HR_REPORT: "/hr/report",

  // Bussiness - Kinh Doanh
  //--Liên hệT
  BUSSINESS_CONTACT: "/bussiness/contact",
  //--Cơ hội
  BUSSINESS_OPPORTUNITY: "/bussiness/opportunity",
  //--Khách hàng
  BUSSINESS_CUSTOMER: "/bussiness/customer",
  //--Tồn kho
  BUSSINESS_INVENTORY: "/bussiness/inventory",
  //--Nhà cung cấp
  BUSSINESS_SUPPLIER: "/bussiness/supplier",
  //--Hợp đồng
  BUSSINESS_CONTRACT: "/bussiness/contract",
  //-Đơn hàng
  BUSSINESS_ORDER: "/bussiness/order",
  //- Báo cáo
  REPORT: "/report",
  //--Dự án
  BUSSINESS_PROJECT: "/bussiness/project",
  //--Chỉ tiêu
  BUSSINESS_TARGET: "/bussiness/target",

  // Finance - Phieu thu chi
  FINANCE_INTERNAL: "/finance/internal",
  FINANCE_EXTERNAL: "/finance/external",
  FINANCE_REPORT: "/finance/report",

  // Account - Kế toán
  //--Kế toán nội bộ
  ACCOUNT_INTERNAL: "/account-internal",
  ACCOUNT_INTERNAL_SETTING: "/account-internal/setting",
  ACCOUNT_INTERNAL_SETTING1: "/account-internal/setting1",
  ACCOUNT_INTERNAL_FUND: "/account-internal/fund",
  ACCOUNT_INTERNAL_BANK: "/account-internal/bank",
  ACCOUNT_INTERNAL_INVOICE: "/account-internal/invoice",
  // Kho
  ACCOUNT_INTERNAL_WAREHOUSE: "/account-internal/warehouse",
  ACCOUNT_WAREHOUSE_OUT: "/account/warehouse/out",
  ACCOUNT_WAREHOUSE_IN: "/account/warehouse/in",
  ACCOUNT_WAREHOUSE_ADJUSTMENT: "/account/warehouse/adjustment",
  ACCOUNT_WAREHOUSE_TRANSFER: "/account/warehouse/transfer",
  ACCOUNT_WAREHOUSE_RETURNED_GOODS: "/account/warehouse/returned-goods",
  ACCOUNT_WAREHOUSE_LIST: "/account/warehouse/list",
  ACCOUNT_WAREHOUSE_GOODS: "/account/warehouse/goods",
  ACCOUNT_WAREHOUSE_SERVICES: "/account/warehouse/services",
  //-- Tài sản
  ACCOUNT_INTERNAL_ASSET: "/account-internal/asset",
  ACCOUNT_INTERNAL_ASSET_FIX: "/account-internal/asset/fix",
  ACCOUNT_INTERNAL_ASSET_TOOLEQUIT: "/account-internal/asset/toolquit",
  //-- Tiền lương
  ACCOUNT_INTERNAL_SALARY: "/account-internal/salary",
  //-- Hợp đồng
  ACCOUNT_INTERNAL_CONTRACT: "/account-internal/contract",
  //--Bút toán
  ACCOUNT_INTERNAL_JOURNAL_ENTRY: "/account-internal/journal-entry",
  //--Báo cáo
  ACCOUNT_INTERNAL_REPORT: "/account-internal/report",
  //-- Cài đặt
  ACCOUNT_INTERNAL_SETTING_ACCOUNT: "/account-internal/setting/account",
  ACCOUNT_INTERNAL_SETTING_ACCOUNTANT: "/account-internal/setting/accountant",
  // quỹ cấp 2
  //+ phiếu thu,phiếu chi
  // Finance - Phieu thu chi
  ACCOUNT_FINANCE_INTERNAL: "/account-internal/internal",
  ACCOUNT_FINANCE_EXTERNAL: "/account-internal/external",
  //  Ngân Hàng (Phiếu báo nợ,Phiếu báo có,Phiếu chuyển tiền)
  ACCOUNT_FINANCE_DEBT: "/account-internal/debt",
  ACCOUNT_FINANCE_CREDIT: "/account-internal/Credit",
  ACCOUNT_FINANCE_CARTIFICATE: "/account-internal/cartificate",
  // kế toán nội bộ/Hóa đơn
  ACCOUNT_FINANCE_SALES_INVOICE: "/account/finance/sales-invoice",
  ACCOUNT_FINANCE_PURCHASE_INVOICE: "/account/finance/purchase-invoice",
  ACCOUNT_FINANCE_RETURNED_GOODS: "/account/finance/returned-goods",

  //--Kế toán thuế
  ACCOUNT_TAX: "/account-tax",
  ACCOUNT_TAX_SETTING: "/account-tax/setting",
  ACCOUNT_TAX_FUND: "/account-tax/fund",
  ACCOUNT_TAX_BANK: "/account-tax/bank",
  ACCOUNT_TAX_INVOICE: "/account-tax/invoice",
  ACCOUNT_TAX_WAREHOUSE: "/account-tax/warehouse",
  //--Kế toán thuế ->Tài sản
  ACCOUNT_TAX_ASSET: "/account-tax/asset",
  ACCOUNT_TAX_ASSET_FIX: "/account-tax/asset/fix",
  ACCOUNT_TAX_ASSET_TOOLEQUIT: "/account-tax/asset/toolquit",
  ACCOUNT_TAX_SALARY: "/account-tax/salary",
  ACCOUNT_TAX_CONTRACT: "/account-tax/contract",
  ACCOUNT_TAX_JOURNAL_ENTRY: "/account-tax/journal-entry",
  ACCOUNT_TAX_REPORT: "/account-tax/report",

  // ASSET - Tài sản
  ASSETS: "/assets",
  ASSET_ASSIGN: "/asset/assign",
  ASSET_REQUEST_FORM: "/asset/request-form",
  ASSET_STATISTICS: "/asset/statistics",
  ASSET_REPORT: "/asset/report",
  // ASSET_WARRANTY_PERIOD: "/asset/WarrantyPeriod",
  // ASSET_EMPLOYEE_ASSETS: "/asset/EmployeeAssets",

  ASSET_CHART: "/asset/chart",
}
export default ROUTER

