import { MailOutlined, PhoneOutlined } from "@ant-design/icons"
import { useState } from "react"
import { useParams } from "react-router-dom"
import ActionBS from "../../common/Action"
import TabsCustom from "src/components/TabsCustom"
import TableCustom from "src/components/Table/CustomTable"
import CustomModal from "src/components/Modal/CustomModal"
import ToolBarCustom from "src/components/ToolBarCustom"
import LayoutContenAdmin from "src/components/Layouts/LayoutContenAdmin"
import { WrapperStyle } from "src/pages/ADMIN/HumanResource/styled"
import { ToolBarStyle } from "../../Setting/common/styled"
import Button from "src/components/MyButton/Button"
import { StyleTabsCustom } from "src/components/TabsCustom/styled"
import AllCartificate from "./AllCartificate"
import IndividualCartificate from "./IndividualCartificate"
import OrganizationCartificate from "./OrganizationCartificate"
import { InputSearchStyle } from "../../../HumanResource/Work/Task/styled"
import { Input } from "antd"
import SvgIcon from "src/components/SvgIcon"


const Cartificate = (props) => {

  // const column = [
  //   {
  //     title: "TT",
  //     dataIndex: "index",
  //     key: `index`,
  //     render: (_, record, i) => {
  //       return <>{i + 1}</>
  //     },
  //   },
  //   {
  //     title: `Mã LH`,
  //     dataIndex: "code_contact",
  //     key: "code_contact",
  //   },
  //   {
  //     title: `Tên liên hệ`,
  //     dataIndex: "contact_name",
  //     key: `contact_name`,
  //   },
  //   {
  //     title: `Liên hệ`,
  //     dataIndex: "contact",
  //     key: `contact`,
  //     render: (_, record) => {
  //       return (
  //         <div>
  //           <div>
  //             <PhoneOutlined style={{ color: "#0091c7", marginRight: 2 }} />
  //             {record.contact.phone}
  //           </div>
  //           <div>
  //             <MailOutlined style={{ color: "red", marginRight: 2 }} />
  //             {record.contact.email}
  //           </div>
  //         </div>
  //       )
  //     },
  //   },
  //   {
  //     title: `Lĩnh vực`,
  //     dataIndex: "area",
  //     key: `area`,
  //   },
  //   {
  //     title: "Trạng thái",
  //     dataIndex: "status",
  //     key: "status",
  //   },
  //   {
  //     title: "Ngày tạo",
  //     dataIndex: "date_create",
  //     key: "date_create",
  //   },
  //   {
  //     title: "Người tạo",
  //     dataIndex: "person_create",
  //     key: "person_create",
  //   },
  //   {
  //     title: "",
  //     dataIndex: "action",
  //     key: "action",
  //     render: (_, record) => {
  //       return <ActionBS />
  //     },
  //   },
  // ]
  const [disableBtn, setDisableBtn] = useState(false);
  const [tab, setTab] = useState('1')

  const LABEL = {
    1: "TẤT CẢ",
    2: "CÁ NHÂN ",
    3: "TỔ CHỨC",
  }


  const items = props => [
    {
        key: "1",
        label: <div className="text-tile-tabs">Tất cả</div>,
        children: <AllCartificate/>,
        
    },
    {
        key: "2",
        label: <div className="text-tile-tabs">Cá nhân</div>,
        children: <IndividualCartificate />,

    },
    {
        key: "3",
        label: <div className="text-tile-tabs">Tổ chức</div>,
        children: <OrganizationCartificate/>,

        
    },
]

  const onChangeTabs = (nameTab) => {
    setDisableBtn(nameTab === "book_setting")
    setTab(nameTab);
};
  return (
    <div>
    <WrapperStyle>
      <ToolBarStyle>
        <div className="left-side">{LABEL[tab]}</div>
        <div className="right-side">
          <Button btnType="primary1" onClick={() => { }} size="small" disabled={disableBtn}>
            Tạo mới
          </Button>
        </div>
      </ToolBarStyle>
      <div style={{display:"flex",justifyContent:"end"}}>
                <InputSearchStyle >
                    <Input
                        className="custom-input"
                        maxLength={50}
                        placeholder="Tìm kiếm"
                        onChange={(e) => { }}
                        onPressEnter={(e) => { }}
                    />
                    <div className="custom-icon-search">
                        <SvgIcon name="searchStatistic" onClick={() => { }} />
                    </div>
                </InputSearchStyle>
                </div>
      <StyleTabsCustom>
        <TabsCustom items={items(props)} defaultActiveKey="external" type="card" onchange={onChangeTabs} />
      </StyleTabsCustom>
    </WrapperStyle>
  </div>
  )
}
export default Cartificate

