import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listDepartment: [],
  listProvince: [],
  listPersonnel: []
}

export const hrSlice = createSlice({
  name: "hr",
  initialState,
  reducers: {
    sliceDepartments: (state, action) => {
      state.listDepartment = action.payload
    },
    setListProvince: (state, action) => {
      state.listProvince = action.payload
    },
    setListPersonnel: (state, action) => {
      state.listPersonnel = action.payload
    }
  },
})

export const { sliceDepartments, setListProvince, setListPersonnel  } = hrSlice.actions

export default hrSlice.reducer

