// import { Spin } from "antd"
import React from "react"
import { BallTriangle } from "react-loader-spinner"
import { useRoutes } from "react-router-dom"
import Cartificate from "src/pages/ADMIN/Accounting/Bank/component/Cartificate"
import Credit from "src/pages/ADMIN/Accounting/Bank/component/Credit"
import Payment from "src/pages/ADMIN/Accounting/Fuld/Component/Payment"
import Receipts from "src/pages/ADMIN/Accounting/Fuld/Component/Receipts"
import Debt from "src/pages/ADMIN/Accounting/Bank/component/Debt"
import BillOfSale from "src/pages/ADMIN/Accounting/Bill/BillOfSale"
import Reciept from "src/pages/ADMIN/Accounting/Bill/Reciept"
import SalesReturns from "src/pages/ADMIN/Accounting/Bill/SalesReturns"
import GoodsAndMaterials from "src/pages/ADMIN/Accounting/Warehouse/GoodsAndMaterials"
import WarehouseTransferSlip from "src/pages/ADMIN/Accounting/Warehouse/WarehouseTransferSlip"
import InventoryAdjustmentSlip from "src/pages/ADMIN/Accounting/Warehouse/InventoryAdjustmentSlip"
import Receipt from "src/pages/ADMIN/Accounting/Warehouse/Receipt"
import DeliveryBill from "src/pages/ADMIN/Accounting/Warehouse/DeliveryBill"
import Service from "src/pages/ADMIN/Accounting/Warehouse/Service"
import WarehouseLists from "src/pages/ADMIN/Accounting/Warehouse/WarehouseList"
import Resale from "src/pages/ADMIN/Accounting/Warehouse/Resale"
import ROUTER from "."

import ReportByEmployee from "../pages/ADMIN/HumanResource/Work/Report/ReportByEmployee"
import ReportByDepartment from "../pages/ADMIN/HumanResource/Work/Report/ReportByDepartment"
import WorkReport from "../pages/ADMIN/HumanResource/Work/Report/WorkReport"
import RecruitmentRequirements from "src/pages/ADMIN/HumanResource/Recruitment"
import CandidateProfile from "src/pages/ADMIN/HumanResource/Recruitment/CandidateProfile"
import InterviewSchedule from "src/pages/ADMIN/HumanResource/Recruitment/InterviewSchedule"
import ReportHR from "src/pages/ADMIN/HumanResource/ReportHR"

// ANONYMOUS
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const InfoAccount = React.lazy(() => import("src/pages/ANONYMOUS/InfoAccount"))
const SettingPermissions = React.lazy(() => import("src/pages/ADMIN/SettingPermissions"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() => import("src/pages/USER/ChangePassword"))

// ADMIN
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
// HR - Nhân sự
const HumanResourceFiles = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Files"),
)
const HRContract = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Contract"),
)
const HRInsurance = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Insurance"),
)
const HRTimekeeping = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/TimeKeeping"),
)
const HRRewards = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Rewards"),
)
const HRPayroll = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Payroll"),
)
const HREvaluation = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Evaluation"),
)
const HRSynthetic = React.lazy(() =>
  import("src/pages/ADMIN/HumanResource/Evaluation/Synthetic"),
)
// Bussiness
const BussinessContact = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/Contact"),
)
const BussinessOpportunity = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/opportunity"),
)
const BussinessCustomer = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/customer"),
)
const BussinessInventory = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/inventory"),
)
const BussinessSupplier = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/supplier"),
)
const BussinessContract = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/contract"),
)
const BussinessOrder = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/order"),
)
const BussinessReport = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/Report"),
)
const BussinessProject = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/project"),
)
const BussinessTarget = React.lazy(() =>
  import("src/pages/ADMIN/Bussiness/target"),
)

const AccountInternalContract = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Contract"),
)
const AccountInternalJEntry = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Entry"),
)

const AccountInternalSetting = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Setting"),
)

const Assets = React.lazy(() => import("src/pages/ADMIN/Assets"))

const Assign = React.lazy(() => import("src/pages/ADMIN/Assets/Assign"))

const RequestForm = React.lazy(() =>
  import("src/pages/ADMIN/Assets/RequestForm"),
)
const Statistics = React.lazy(() =>
  import("src/pages/ADMIN/Assets/Depreciation"),
)

// Finance
const FinanceInternal = React.lazy(() =>
  import("src/pages/ADMIN/Finance/Internal"),
)
const FinanceExternal = React.lazy(() =>
  import("src/pages/ADMIN/Finance/External"),
)
const FinanceReport = React.lazy(() =>
  import("src/pages/ADMIN/Finance/Report"),
)

// Work
const Task = React.lazy(() => import("../pages/ADMIN/HumanResource/Work/Task"))

// Acount -- Asset
const AccountAssetFixed = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Asset/fix"),
)
const AccountAssetToolequit = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Asset/tool-equit"),
)
const AccountSalary = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Salary"),
)
const AccountReport = React.lazy(() =>
  import("src/pages/ADMIN/Accounting/Report"),
)
//--Kế toán thuế
const AccountantTaxSetting = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Setting"),
)
const AccountantTaxAssetFix = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Asset/fix"),
)
const AccountantTaxAssetToolequit = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Asset/tool-equit"),
)
const AccountantTaxSalary = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Salary"),
)
const AccountantTaxContract = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Contract"),
)
const AccountantTaxJEntry = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Entry"),
)
const AccountantTaxReport = React.lazy(() =>
  import("src/pages/ADMIN/AccountantTax/Report"),
)

// Đơn từ
const Application = React.lazy(() => import("src/pages/ADMIN/HumanResource/Application"))

const AssetCharts = React.lazy(() => import("src/pages/ADMIN/Assets/charts"))
const AssetReport = React.lazy(() => import("src/pages/ADMIN/Assets/report"))
const DocumentShare = React.lazy(() => import("src/pages/ADMIN/HumanResource/Document"))
const TargetCompany = React.lazy(() => import("src/pages/ADMIN/HumanResource/Target"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#01638d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    // Hồ sơ nhân sự
    children: [
      // HR -- Nhân sự
      {
        path: ROUTER.HR_FILES,
        element: (
          <LazyLoadingComponent>
            <HumanResourceFiles />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.HR_CONTRACT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HRContract />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.HR_INSURANCE,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HRInsurance />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.HR_TIMEKEEPING,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HRTimekeeping />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.HR_REWARDS,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HRRewards />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // // Nhân sự -> Bảng lương
      // {
      //   path: ROUTER.HR_PAYROLL,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HRPayroll />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // // Nhân sự -> Đánh giá
      // {
      //   path: ROUTER.HR_EVALUATION,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HREvaluation />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.HR_EVALUATION_SYNTHETIC,
      //   element: (
      //     <LazyLoadingComponent>
      //       <HRSynthetic />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // // Nhân sự  -> Tuyển dụng
      // // Yêu cầu tuyển dụng
      // {
      //   path: ROUTER.HR_JOB_REQUESTS,
      //   element: (
      //     <LazyLoadingComponent>
      //       <RecruitmentRequirements />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // // hồ sơ ứng viên
      // {
      //   path: ROUTER.HR_CANDIDATE_PROFILES,
      //   element: (
      //     <LazyLoadingComponent>
      //       <CandidateProfile />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // // lịch phỏng vấn
      // {
      //   path: ROUTER.HR_INTERVIEW_SCHEDULES,
      //   element: (
      //     <LazyLoadingComponent>
      //       <InterviewSchedule />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // // Nhân sự -> Báo cáo
      // {
      //   path: ROUTER.HR_REPORT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ReportHR />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // Nhân sự -> Đơn từ
      // {
      //   path: `${ROUTER.APPLICATION}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Application />
      //     </LazyLoadingComponent>
      //   ),
      // },
      //  Nhân sự -> Công việc
      // {
      //   path: ROUTER.WORK_TASK,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Task />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.WORK_TASK_JOB,
      //   element: (
      //     <LazyLoadingComponent>
      //       <WorkReport />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.WORK_TASK_STAFF,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ReportByEmployee />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.WORK_TASK_DEPARTMENT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ReportByDepartment />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // Nhân sự -> Mục tiêu
      // {
      //   path: ROUTER.TARGET,
      //   element: (
      //     <LazyLoadingComponent>
      //       <TargetCompany />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // Nhân sự -> Tài liệu
      // {
      //   path: `${ROUTER.DOCUMENT}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <DocumentShare />
      //     </LazyLoadingComponent>
      //   ),
      // },

      // Bussiness - Kinh Doanh
      {
        path: ROUTER.BUSSINESS_CONTACT,
        element: (
          <LazyLoadingComponent>
            <BussinessContact />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.BUSSINESS_OPPORTUNITY,
      //   element: (
      //     <LazyLoadingComponent>
      //       <BussinessOpportunity />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.BUSSINESS_CUSTOMER,
        element: (
          <LazyLoadingComponent>
            <BussinessCustomer />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: `${ROUTER.BUSSINESS_INVENTORY}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <BussinessInventory />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.BUSSINESS_SUPPLIER,
      //   element: (
      //     <LazyLoadingComponent>
      //       <BussinessSupplier />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.BUSSINESS_CONTRACT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <BussinessContract />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        // path: `${ROUTER.BUSSINESS_ORDER}/:mode`,
        path: ROUTER.BUSSINESS_ORDER,
        element: (
          <LazyLoadingComponent>
            <BussinessOrder />
          </LazyLoadingComponent>
        ),
      },
      {
        // path: `${ROUTER.BUSSINESS_ORDER}/:mode`,
        path: `${ROUTER.REPORT}/:mode`,
        element: (
          <LazyLoadingComponent>
            <BussinessReport />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.BUSSINESS_PROJECT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <BussinessProject />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: `${ROUTER.BUSSINESS_TARGET}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <BussinessTarget />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // FINANCE - Tài chính
      // {
      //   path: ROUTER.FINANCE_INTERNAL,
      //   element: (
      //     <LazyLoadingComponent>
      //       <FinanceInternal />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.FINANCE_EXTERNAL,
      //   element: (
      //     <LazyLoadingComponent>
      //       <FinanceExternal />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.FINANCE_REPORT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <FinanceReport />
      //     </LazyLoadingComponent>
      //   ),
      // },

      //-- Kế toán nội bộ -> Tiền lương
      // {
      //   path: `${ROUTER.ACCOUNT_INTERNAL_SALARY}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountSalary />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // //-- Kế toán nội bộ -> Tài sản
      // {
      //   path: `${ROUTER.ACCOUNT_INTERNAL_ASSET_FIX}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountAssetFixed />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: `${ROUTER.ACCOUNT_INTERNAL_ASSET_TOOLEQUIT}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountAssetToolequit />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // //-- Kế toán nội bộ -> Hợp đồng
      // {
      //   path: `${ROUTER.ACCOUNT_INTERNAL_CONTRACT}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountInternalContract />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // //-- Kế toán nội bộ -> Bút toán
      // {
      //   path: `${ROUTER.ACCOUNT_INTERNAL_JOURNAL_ENTRY}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountInternalJEntry />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // //-- Kế toán nội bộ -> Báo cáo
      // {
      //   path: ROUTER.ACCOUNT_INTERNAL_REPORT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountReport />
      //     </LazyLoadingComponent>
      //   ),
      // },

      // Phiếu thu phiếu chi
      // {
      //   path: ROUTER.ACCOUNT_FINANCE_INTERNAL,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Payment />
      //     </LazyLoadingComponent>
      //   ),
      // },
      //-- Kế toán nội bộ -> Cài đặt
      // {
      //   path: `${ROUTER.ACCOUNT_INTERNAL_SETTING}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AccountInternalSetting />
      //     </LazyLoadingComponent>
      //   ),
      // },

      {
        path: `${ROUTER.ACCOUNT_TAX_SETTING}/:mode`,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxSetting />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ACCOUNT_TAX_ASSET_FIX}/:mode`,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxAssetFix />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ACCOUNT_TAX_ASSET_TOOLEQUIT}/:mode`,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxAssetToolequit />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ACCOUNT_TAX_SALARY}/:mode`,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxSalary />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ACCOUNT_TAX_CONTRACT}/:mode`,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxContract />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ACCOUNT_TAX_JOURNAL_ENTRY}/:mode`,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxJEntry />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_TAX_REPORT,
        element: (
          <LazyLoadingComponent>
            <AccountantTaxReport />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_FINANCE_EXTERNAL,
        element: (
          <LazyLoadingComponent>
            <Receipts />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_FINANCE_DEBT,
        element: (
          <LazyLoadingComponent>
            <Debt />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_FINANCE_CREDIT,
        element: (
          <LazyLoadingComponent>
            <Credit />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_FINANCE_CARTIFICATE,
        element: (
          <LazyLoadingComponent>
            <Cartificate />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ASSET_CHART,
        element: (
          <LazyLoadingComponent>
            <AssetCharts />
          </LazyLoadingComponent>
        ),
      },
      // kế toán nội bộ/Hóa đơn
      {
        path: ROUTER.ACCOUNT_FINANCE_SALES_INVOICE,
        element: (
          <LazyLoadingComponent>
            <BillOfSale />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_FINANCE_PURCHASE_INVOICE,
        element: (
          <LazyLoadingComponent>
            <Reciept />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT_FINANCE_RETURNED_GOODS,
        element: (
          <LazyLoadingComponent>
            <SalesReturns />
          </LazyLoadingComponent>
        ),
      },

      // Kho
      // Phiếu xuất kho
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_OUT,
        element: (
          <LazyLoadingComponent>
            <DeliveryBill />
          </LazyLoadingComponent>
        ),
      },
      // Phiếu nhập kho
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_IN,
        element: (
          <LazyLoadingComponent>
            <Receipt />
          </LazyLoadingComponent>
        ),
      },
      // Phiếu điều chỉnh tồn kho
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_ADJUSTMENT,
        element: (
          <LazyLoadingComponent>
            <InventoryAdjustmentSlip />
          </LazyLoadingComponent>
        ),
      },
      // Phiếu điều chuyển kho
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_TRANSFER,
        element: (
          <LazyLoadingComponent>
            <WarehouseTransferSlip />
          </LazyLoadingComponent>
        ),
      },
      // Hàng bán chả lại
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_RETURNED_GOODS,
        element: (
          <LazyLoadingComponent>
            <Resale />
          </LazyLoadingComponent>
        ),
      },
      // Danh sách kho
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_LIST,
        element: (
          <LazyLoadingComponent>
            <WarehouseLists />
          </LazyLoadingComponent>
        ),
      },
      // Vật tư hàng hóa
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_GOODS,
        element: (
          <LazyLoadingComponent>
            <GoodsAndMaterials />
          </LazyLoadingComponent>
        ),
      },
      // Dịch vụ
      {
        path: ROUTER.ACCOUNT_WAREHOUSE_SERVICES,
        element: (
          <LazyLoadingComponent>
            <Service />
          </LazyLoadingComponent>
        ),
      },
      // // Tài sản
      // {
      //   path: ROUTER.ASSETS,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Assets />
      //     </LazyLoadingComponent>
      //   ),
      // },

      // {
      //   path: `${ROUTER.ASSET_REPORT}/:mode`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <AssetReport />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.ASSET_ASSIGN,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Assign />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.ASSET_REQUEST_FORM,
      //   element: (
      //     <LazyLoadingComponent>
      //       <RequestForm />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.ASSET_STATISTICS,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Statistics />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: `${ROUTER.ASSET_STATISTICS}`,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Statistics />
      //     </LazyLoadingComponent>
      //   ),
      // },
    ],
  },
  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT,
        element: (
          <LazyLoadingComponent>
            <InfoAccount />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SETTING_PERMISSIONS,
        element: (
          <LazyLoadingComponent>
            <SettingPermissions />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      // {
      //   path: ROUTER.HOME,
      //   element: (
      //     <LazyLoadingComponent>
      //       <Home />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter

